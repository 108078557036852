import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from "use-debounce";
/*import type { ApiResponse } from './types';*/
import BoldedText from './components/BoldedText';
import Image from './components/Image';
import { global } from '../../../constants/globals';
import { cn } from '../../../utils/classNames';

const client = new APICore.ProductSearchAPIClient(global.apiBaseURL);
const client2 = new APICore.BlogAPIClient(global.apiBaseURL);

interface SearchBarProps {
    inputPlaceholder?: string;
    isBlog?: boolean;
}

const searchProducts = (text) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    fetch(`/api/webmethods/textsearch?searchtext=${text}`, requestOptions).then(async (response) => {
        const data = await response.json();
        window.location = data;
    });
};

const searchBlogs = (text) => {
    window.location.href = `?q=${text}`;
};

function SearchBar({ inputPlaceholder = 'Search...', isBlog = false }: SearchBarProps) {
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
    const [internalSelectedOption, setInternalSelectedOption] = useState<string>();
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [data, setData] = useState<APICore.TypeAsYouSearch[] | undefined>();
    const inputRef = useRef<HTMLInputElement>(null);
    let index = 0;

    useEffect(() => {
        // get data if the input length is greater than 1 character
        if (debouncedSearchTerm && debouncedSearchTerm.length > 1) {
            if (isBlog) {
                client2
                    .searchAsYouTypeArticles(debouncedSearchTerm, 5, 1, +global.languageId, +global.websiteId, null)
                    .then((json) => {
                        if (json.data) {
                            setData(json.data);
                        }
                    })
                    .then(() => setDropdownOpened(true));
            } else {
                client
                    .productSearchAsYouTypeV2(global.currencyCulture, global.websiteId, {
                        currencyId: +global.currencyId,
                        deliveryCountryId: +global.delCountryId,
                        languageId: +global.languageId,
                        websiteId: +global.websiteId,
                        query: debouncedSearchTerm
                    })
                    .then((json) => {
                        if (json.data) {
                            setData(json.data);
                        }
                    })
                    .then(() => setDropdownOpened(true));
            }
            // const vod = client.myVideoList(
            //     global.websiteId,
            //     global.currencyId,
            //     global.delCountryId,
            //     global.languageId)
            //     .then(
            //     (json) => {
            //         console.log(json);
            //         json.data?.forEach((item) => {
            //             console.log(item);

            //         }
            //     }
            // );
        }
        // clears the data and hides the dropdown if there is no input
        if (debouncedSearchTerm === undefined || debouncedSearchTerm.length === 0) {
            setData(undefined);
            setDropdownOpened(false);
        }
    }, [debouncedSearchTerm, isBlog]);

    const flattenedData = useMemo(
        () => data?.map((item) => item.data?.flatMap((item) => item.name)).flat(),
        [data]
    );

    const anchorRefs: React.RefObject<HTMLAnchorElement>[] = useMemo(
        () =>
            Array(flattenedData?.length)
                .fill(0)
                .map(() => React.createRef()),
        [flattenedData?.length]
    );

    useEffect(() => {
        if (flattenedData) {
            setInternalSelectedOption(flattenedData[focusedIndex]);
        }
    }, [flattenedData, focusedIndex]);

    const onManualSearch = (query: string) => {
        if (query && query.length > 1) {
            if (isBlog) {
                searchBlogs(query);
            } else {
                searchProducts(query);
            }
        }
    };

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setFocusedIndex(-1);

        if (internalSelectedOption !== undefined) {
            setInternalSelectedOption(event.target.value);
        }
    };

    const onFocus = () => {
        if (searchTerm && searchTerm.length > 1) {
            setDropdownOpened(true);
        }
    };

    const handleInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (flattenedData) {
                setFocusedIndex((prev) => {
                    return (prev + 1) % flattenedData.length;
                });
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (flattenedData) {
                setFocusedIndex((prev) => {
                    if (prev === -1) return 0;
                    if (prev === 0) return flattenedData.length - 1;
                    return prev - 1;
                });
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            if (searchTerm && searchTerm.length > 1) {
                if (internalSelectedOption === undefined) {
                    onManualSearch(searchTerm);
                }

                if (anchorRefs[focusedIndex].current) {
                    anchorRefs[focusedIndex].current?.click();
                }
            }
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setDropdownOpened(false);
        } else if (event.key === 'Backspace' || event.key === 'Delete') {
            setInternalSelectedOption(searchTerm);
        } else if (event.key === 'Tab') {
            setDropdownOpened(false);
            setFocusedIndex(-1);
        }
    };

    const onItemHover = (event: MouseEvent<HTMLDivElement>, name: string) => {
        event.preventDefault();
        setInternalSelectedOption(name);
    };

    const onItemLeave = () => {
        // sets the visible search text to focusedIndex if there is any
        // otherwise sets the visible search text to the latest search text
        if (focusedIndex !== -1 && flattenedData) {
            return setInternalSelectedOption(flattenedData[focusedIndex]);
        }
        setInternalSelectedOption(searchTerm);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                //onClickOutside && onClickOutside();
                setDropdownOpened(false);
                setFocusedIndex(-1);
                setInternalSelectedOption(undefined);
            } else {
                if (event.target.value && event.target.value.length !== 0) {
                    setDropdownOpened(true);
                    return;
                }
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <>
            <div className='search__root' onKeyDown={handleInputKeydown}>
                <input
                    id='desktopSearch'
                    type='search'
                    role='presentation'
                    name='website-search'
                    placeholder={inputPlaceholder}
                    maxLength={45}
                    className='txtSearch'
                    autoComplete='off'
                    autoCorrect='off'
                    spellCheck='false'
                    onChange={(event) => onInputChange(event)}
                    onFocus={() => onFocus()}
                    value={internalSelectedOption}
                    ref={inputRef}
                />
                <button type="button" id='desktopButton2' className='icon--search' aria-label="Search query" onClick={() => onManualSearch(searchTerm!)}>
                    <i className='fa fa-search' aria-hidden='true'></i>
                </button>
            </div>
            {Boolean(dropdownOpened && data) && (
                <div className='searchResults'>
                    <div className='searchResults__inner' tabIndex={0}>
                        {data?.map((result) => {
                            return (
                                <React.Fragment key={result.type}>
                                    {Boolean(result.type) && (
                                        <div className='searchResults__inner--title' key={result.type}>
                                            {result.type}
                                        </div>
                                    )}
                                    {result.data?.map((data) => {
                                        const focused = index === focusedIndex;

                                        index += 1;

                                        switch (result.type) {
                                            case 'Products':
                                                return (
                                                    <a
                                                        href={`/${global.langCult}${data.urlPart}`}
                                                        key={data.name}
                                                        ref={anchorRefs[index - 1]}
                                                        data-name={data.name}
                                                    >
                                                        <div
                                                            className={cn(
                                                                'productHolder',
                                                                focused ? 'searchResults__inner--itemFocused' : ''
                                                            )}
                                                            onMouseEnter={(event) =>
                                                                onItemHover(event, data.searchBoxName!)
                                                            }
                                                            onMouseLeave={onItemLeave}
                                                        >
                                                            <Image
                                                                src={`${global.productImageURL}50/${data.imageFileName}`}
                                                                placeholderImg={`/global/images/icons/favicons/favicon-32x32.png`}
                                                            />
                                                            <BoldedText
                                                                text={data.name!}
                                                                shouldBeBold={searchTerm!}
                                                                className='textHolder'
                                                            />
                                                        </div>
                                                    </a>
                                                );
                                            default:
                                                return (
                                                    <a
                                                        href={`/${global.langCult}${data.urlPart}`}
                                                        key={data.name}
                                                        ref={anchorRefs[index - 1]}
                                                    >
                                                        <div
                                                            className={cn(
                                                                'termHolder',
                                                                focused ? 'searchResults__inner--itemFocused' : ''
                                                            )}
                                                            key={data.name}
                                                            onMouseEnter={(event) =>
                                                                onItemHover(event, data.searchBoxName!)
                                                            }
                                                            onMouseLeave={onItemLeave}
                                                        >
                                                            <BoldedText text={data.name!} shouldBeBold={searchTerm!} />
                                                        </div>
                                                    </a>
                                                );
                                        }
                                    })}
                                </React.Fragment>
                            );
                        })}
                        {/* // TODO: remove this ?? */}
                        {/* {Boolean(seeMore) && (
                            <div className='search--more' onClick={handleSearch}>
                                See more results for `&quot;`{searchTerm}`&quot;` {`>`}
                            </div>
                        )} */}
                    </div>
                </div>
            )}
        </>
    );
}

export default SearchBar;
