/**
 * *Returns a space-separated string of class names from an array of class
names.*
 * @param  - `classes` is an array of strings, and it's the only required
parameter.
 * @returns The function cn() is returning a string.
 */
export function cn(...classes: (string | false | undefined | null)[]) {
    return classes.filter(Boolean).join(' ');
}
