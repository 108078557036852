import React from 'react';

interface SectionDividerProps {
    title?: string;
    subtitle?: string;
}

const SectionDivider: React.FC<SectionDividerProps> = ({ title, subtitle }) => {
    return (
        <div className='sectionDivider'>
            {title && <h3 className='sectionDivider__title'>{title}</h3>}
            <hr className='sectionDivider__line' />
            {subtitle && <p className='sectionDivider__subTitle'>{subtitle}</p>}
        </div>
    );
};

export default SectionDivider;
