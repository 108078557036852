declare const _langID: number;
declare const _websiteID: number;
declare const _currencyID: number;
declare const _currencyCulture: string;
declare const _deliveryCountryID: number;
declare const _apiBaseURL: string;
declare const _productImageURL: string;
declare const _cdnBaseURL: string;
declare const _phoneNumber: string;
declare const _websiteURL: string;
declare const langCult: string;
declare const _channelName: string;

export const global = {
    languageId: _langID,
    websiteId: _websiteID,
    customerId: '123AB',
    currencyId: _currencyID,
    currencyCulture: _currencyCulture,
    delCountryId: _deliveryCountryID,
    phoneNumber: _phoneNumber,
    apiBaseURL: _apiBaseURL,
    productImageURL: _productImageURL,
    cdn: _cdnBaseURL,
    websiteURL: _websiteURL,
    langCult: langCult,
    cacheKeyDynamic: 0,
    cacheKeyAuction: 0,
    cacheKeyDotd: 0,
    cacheKeyBestSellers: 0,
    loggedIn: gemporiaAPP.common.isLoggedIn,
    channelName: _channelName,
};
