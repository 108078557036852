import React from 'react';
import ReactDOM from 'react-dom';
import MainSearch from './mainSearch';
import RecentlyViewed from './recentlyViewed';

const container = document.getElementById('recentlyViewed');
ReactDOM.render(<RecentlyViewed />, container);

const searchBar = document.getElementById('searchBar');
ReactDOM.render(<MainSearch />, searchBar);
